<template>

  <div>
    <topslogan/>
    <div :style="{backgroundImage: 'url('+server_url+categoryinfoData.cover_image+')'}" class="top_head">
      <topnav :category_id="$route.query.id" :category_key="$route.query.key"></topnav>
    </div>
    <div class="breadcrumb_bg">
      <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item>
          <router-link to="/">
            <a-icon type="home"/>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ categoryinfoData.name }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="PMTinfo"  v-if="categoryinfo_show">
      <!--        {{categoryinfo}}-->
      <div class="infoleft" v-if="categoryinfopidData.more_info">
        <h3>{{categoryinfopidData.more_info.title}}</h3>
        <div class="info">{{categoryinfopidData.more_info.content}}</div>
        <!--            <a-button class="btn-danger" shape="round" size="large" type="danger">-->
        <!--              加入会员-->
        <!--              <a-icon type="arrow-right"/>-->
        <!--            </a-button>-->
      </div>

      <div class="inforight" :style="{backgroundImage: 'url('+categoryinfopidData.more_info.cover_img+')'}">

      </div>
    </div>
    <div class="category_list">
      <div class="left_container">
        <!--        v-if="categoryinfoData.child.length !== 0"-->
        <div>
          <a-list :data-source="categoryinfopidData.child" bordered class="left">
            <a-list-item slot="renderItem" slot-scope="item, index" :class="item.id === show_id? 'active' : ''"
                         class="title" @click='changeCategory(1,10,item.id,1,"")'>
                <span v-if="language ==='cn'">
                              {{ item.name.cn }}
                            </span>
              <span v-if="language ==='en'">
                              {{ item.name.en }}
                            </span>
              <span v-if="language ==='fr'">
                            {{ item.name.fr }}
                            </span>

            </a-list-item>
          </a-list>
        </div>

      </div>
      <div class="right_container">

        <a-list :data-source="articlesData" :loading="loading" :pagination="false" item-layout="horizontal" v-show="is_video===2">
          <a-list-item slot="renderItem" slot-scope="item, index" class="articles_list" >

            <div class="left_info">
              <h4>{{ item.title }}</h4>
              <div>
                 <span v-if="language ==='cn'">
                              {{ dictionary.cn.Lastupdate}}
                            </span>
                <span v-if="language ==='en'">
                              {{ dictionary.en.Lastupdate}}
                            </span>
                <span v-if="language ==='fr'">
                              {{ dictionary.fr.Lastupdate}}
                            </span>

                {{ item.created_at | dateFormat('yyyy-M-d HH:mm') }}</div>
              <div v-if="item.more_info_json===null" class="description">
                暂无描述信息
              </div>
              <div v-if="item.more_info_json!==null" class="description">
                {{ JSON.parse(item.more_info_json).description }}
              </div>
              <div>
                <a-button class="btn-red" shape="round" size="large" type="danger" @click="gotoArticle(item.id)">
                   <span v-if="language ==='cn'">
                              {{ dictionary.cn.Readmore}}
                            </span>
                  <span v-if="language ==='en'">
                              {{ dictionary.en.Readmore}}
                            </span>
                  <span v-if="language ==='fr'">
                              {{ dictionary.fr.Readmore}}
                            </span>


                  <a-icon type="arrow-right"/>
                </a-button>

              </div>

            </div>
            <div class="right_info"><img
                slot="extra"
                :alt="item.title"
                :src="item.cover_image"
                class="right_img"
            /></div>
          </a-list-item>
          <div style="text-align:right">
          <a-pagination :defaultPageSize="pagination.pageSize" v-model="current" :total="pagination.total" show-less-items v-show="is_video===2" @change="onChange"/>
          </div>
        </a-list>
        <div class="news_list" v-show="is_video===1">
          <div v-for="(item,index) in articlesData" :key="index" @click="gotoArticle(item.id)"
               :style="{backgroundImage: 'url('+item.cover_image+')'}" class="news_info">
            <div class="formcategory">{{ item.articles_category_name }}</div>
            <div class="title">{{ item.title }}</div>
            <div class="times">{{ item.created_at | dateFormat('yyyy-M-d HH:mm') }}</div>
            <div class="mask"></div>
          </div>

        </div>
        <div style="text-align:right">
          <a-pagination :defaultPageSize="pagination.pageSize" v-model="current" :total="pagination.total" show-less-items v-show="is_video===1"  @change="onChange"/>
        </div>

      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>

import Config from "@/config";
import topnav from "@/views/topnav";
import topslogan from "@/views/topslogan";
import bottom from "@/views/bottom";

export default {
  components: {topslogan, topnav, bottom},
  name: "category_list",
  props: ['key'],
  data() {
    return {
      articles_category_id: "",
      articles_category_pid: "",
      server_url: Config.data.server_url,
      dictionary: Config.data.dictionary,
      categoryinfoData: "",
      categoryinfopidData: "",
      breadcrumb_name: "",
      show_id: "",
      loading: false,
      is_video: false,
      categoryinfo_show: false,
      language: localStorage.language,
      categoryData: [],
      categoryinfo: [],
      articlesData: [],
      current:1,

      pagination: {
        // onChange: page => {
        //   console.log(page);
        // },
        pageSize: 10,
      },
    }
  },
  created() {

    console.log(this.$route.query.id)
    console.log(this.$route.query.key)
    this.articles_category_id = this.$route.query.id * 1
    this.articles_category_pid = this.$route.query.key * 1
    Promise.all([this.getcategoryinfo(this.articles_category_id, 1)]).then(() => {

      this.loading = false;
    }).catch((error) => {
      console.log(error)
    })


  },
  methods: {

    gotoArticle(item) {
      console.log("goto", item)
      this.$router.push({
        path: 'article',
        query: {
          language: this.language,
          articleid: item
        }
      });
    },
    async getcategoryinfo(articles_category_id, is_show) {
      console.log("请求id", articles_category_id);
      this.categoryinfoData = [];
      this.loading = true;
      let result = await this.$get("/api/customer/articleCategory/info", {
        params: {

          articles_category_id: articles_category_id,
          is_show: is_show,
          language: this.language,
        }
      });

      if (result.status === true) {
        console.log("categoryinfoData", result.data);
        console.log("is_video", result.data.is_video);
        this.is_video=result.data.is_video

        this.categoryinfoData = result.data;

        if (this.language === "cn") {
          this.breadcrumbname = this.categoryinfoData.name.cn
        }
        if (this.language === "en") {
          this.breadcrumbname = this.categoryinfoData.name.en
        }
        if (this.language === "fr") {
          this.breadcrumbname = this.categoryinfoData.name.fr
        }

        if (result.data.child.length === 0) {
          console.log("没有子栏目");
          if (this.articles_category_pid === this.articles_category_id) {
            this.categoryinfopidData = result.data;
          } else {
            this.getcategorypidinfo(this.articles_category_pid, 1)
          }
          // if (this.show_id === "") {
          //   this.show_id = result.data.child[0].id
          // } else {
          //   this.show_id = this.articles_category_id
          // }
          this.show_id = this.articles_category_id

          this.getcategorylist(1, this.pagination.pageSize, this.show_id, 1, 1)
        } else {
          console.log("有子栏目",);
          if (this.articles_category_pid === this.articles_category_id) {

            this.categoryinfopidData = result.data;
          } else {
            this.getcategorypidinfo(this.articles_category_pid, 1)
          }
          if (this.show_id === "") {
            this.show_id = result.data.child[0].id
          } else {
            this.show_id = this.articles_category_id
          }

          this.getcategorylist(1, this.pagination.pageSize, this.show_id, 1, 1)
        }


        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },
    async getcategorypidinfo(articles_category_id, is_show) {
      console.log("请求id", articles_category_id);
      this.categoryinfopidData = [];
      this.loading = true;
      let result = await this.$get("/api/customer/articleCategory/info", {
        params: {

          articles_category_id: articles_category_id,
          is_show: is_show,
          language: this.language,
        }
      });

      if (result.status === true) {
        console.log("categoryinfoData", result.data);
        this.categoryinfopidData = result.data;
        this.categoryinfo_show = true;
        // if(this.language ==="cn"){
        //   this.breadcrumbname=this.categoryinfoData.name.cn
        // }
        // if(this.language ==="en"){
        //   this.breadcrumbname=this.categoryinfoData.name.en
        // }
        // if(this.language ==="fr"){
        //   this.breadcrumbname=this.categoryinfoData.name.fr
        // }
        //
        // if(result.data.child.length === 0){
        //   console.log("没有子栏目" );
        //   this.getcategorylist(1, 20,articles_category_id, 1, 1)
        // }else{
        //   console.log("有子栏目",);
        //   this.show_id=result.data.child[0].id
        //   this.getcategorylist(1, 20,this.show_id, 1, 1)
        // }


        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },
    async onChange(pagination) {
      console.log(pagination)
      await this.getcategorylist( pagination,15, this.show_id, 1, 1)
    },
    async getcategorylist(page, limit, articles_category_id, getall, is_show) {
      console.log("g请求id", articles_category_id);
      if(
          articles_category_id===45
      ){
        this.is_video=1
        this.pagination.pageSize = 15
        console.log("is_video", this.is_video);

      }else{
        this.is_video=2
        this.pagination.pageSize = 10
        console.log("is_video", this.is_video);
      }
      this.articlesData = [];
      this.loading = true;
      let result = await this.$get("/api/customer/article/list", {
        params: {
          page: page,
          limit: this.pagination.pageSize,
          articles_category_id: articles_category_id,
          get_all: getall,
          is_show: is_show,
          language: this.language,
        }
      });

      if (result.status === true) {

        this.articlesData = result.data.list;
        let pagination = {...this.pagination};
        pagination.total = result.data.total;
        this.pagination = pagination;
        console.log("pagination" ,this.pagination);
        this.loading = false;
      }

      this.loading = false;
    },
    async changeCategory(page, limit, articles_category_id, getall, is_show) {
      this.show_id = articles_category_id
      console.log("c请求id", articles_category_id);
      if(
          articles_category_id===45
      ){
        this.is_video=1
        this.pagination.pageSize = 15
        console.log("is_video", this.is_video);

      }else{
        this.is_video=2
        this.pagination.pageSize = 10
        console.log("is_video", this.is_video);
      }
      this.articlesData = [];
      this.loading = true;
      let result = await this.$get("/api/customer/article/list", {
        params: {
          page: page,
          limit: this.pagination.pageSize,
          articles_category_id: articles_category_id,
          get_all: getall,
          is_show: is_show,
          language: this.language,
        }
      });

      if (result.status === true) {

        this.articlesData = result.data.list;
        let pagination = {...this.pagination};
        pagination.total = result.data.total;
        this.pagination = pagination;
        // console.log("日历Data" ,JSON.parse(this.calendarData));
        this.articles_category_id = articles_category_id
        this.$router.push({
          query: {
            id: articles_category_id,
            language: this.language,
            key: this.articles_category_pid,
          }
        })
        this.loading = false;
      }

      this.loading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.top_head {
  max-width: 1920px !important;
  margin-left: auto;
  margin-right: auto;
  height: 560px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

}

.breadcrumb_bg {
  background: #F2F4F5;
  padding-top: 18px;
  height: 64px;
}

.breadcrumb {
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;

}

.title {
  color: #fff;
}

.active {
  color: #fff;
  background: #CE1126;
}

.category_list {
  height: 100%;

  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 80px;

  .right_container {
    width: 100%;
  }

  .left_container {

    .left {
      width: 170px !important;
      padding: 9px 10px;
      border-radius: 8px;
      margin-right: 38px;
      color: #fff;
      background-color: #002654;
      cursor: pointer;

      /deep/ .ant-list-item {
        border-bottom: 0px solid #e8e8e8 !important;
      }
    }
  }

  .left_info {
    padding-top: 32px;

    padding-left: 40px;
    padding-right: 40px;

    h4 {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 34px;
      margin-top: 0;
      padding-top: 0;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      width: 100%;
      color: #000000;
      line-height: 28px;
      margin-bottom: 20px;
      min-height: 100px;
      max-height: 100px;

    }
  }

  .btn-red {
    color: #fff;
    background-color: #CE1126 !important;
    border-color: #CE1126 !important;
  }

  .articles_list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 34px;
    background-color: #F2F4F5;
    border-radius: 8px;
  }

  .right_info {
    width: 266px;
  }

  .right_img {
    width: 266px;
    height: 280px;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.PMTinfo {
  max-width: 1248px;
  min-height: 378px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  background-color: #F2F4F5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;

  .infoleft {
    padding: 20px 38px;
    width: 70%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;


    h3 {
      font-weight: bolder;
      font-size: 24px;
    }

    .info {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 32px;
      text-indent:2em;
      overflow: hidden;
      text-align: justify;
    }
  }

  .inforight {
    width: 450px;
    //background-color: #573e51;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 20px;
    background-size: cover;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.news_info {
  background-repeat: no-repeat;
  position: relative;
  background-position: center top;
  background-size: contain;
  min-width: 30%;
  max-width: 30%;
  margin-right: 5%;
  margin-bottom: 30px;
  max-height: 360px;
  min-height: 360px;

  color: #fff;
  border-radius: 8px 8px 8px 8px;

  .formcategory {
    background-color: #002654;
    padding: 6px 16px;
    min-width: 87px;
    font-size: 14px;
    color: #fff;
    min-height: 32px;
    border-radius: 8px 0px 8px 0px;
    display: inline-block;
  }

  .times {
    position: absolute;
    bottom: 16px;
    left: 16px;
    font-weight: bold;
    font-size: 16px;
    z-index: 200;
  }

  .title {
    position: absolute;
    bottom: 42px;
    left: 16px;
    font-weight: bold;
    line-height: 140%;
    font-size: 22px;
    z-index: 200;
  }

  .mask {
    background: linear-gradient(180deg, rgba(0, 37, 83, 0.1) 43%, #002654 100%);
    position: absolute;
    display: block;
    z-index: 100;
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 8px 8px;
    top: 0;
    left: 0;
    opacity: 0.8;
  }
}
.news_list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  flex-wrap:wrap;
  .news_info:nth-child(3n) {
    margin-right: 0%;
  }
}
</style>